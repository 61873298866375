//call restful://service.daline.com.cn:9090/SYS-PUINFO-V0X0X1/pu?fidx=GenVueInterface&type=QueryData to auto generate the interface, do not modify mannually
import restful from './restful'
// function dealParams(info) {
//     var str = ''
//     for (var key in info) {
//         str = str+'&'+key+'='+encodeURIComponent(info[key])
//     }
//     return str
// }
// --------------- article -----------------
export function DALINEWEB_ARTICLECENTER_V0X0X1_getArticleInfo (info) {
    return restful.post('/pu-articleserver-v1x0x1/pu?fidx=getArticleInfo',info)
}
export function DALINEWEB_ARTICLECENTER_V0X0X1_getArticleList (info) {
    return restful.post('/pu-articleserver-v1x0x1/pu?fidx=getArticleList',info)
}
export function DALINEWEB_ARTICLECENTER_V0X0X1_getArticlewithToped (info) {
    return restful.post('/pu-articleserver-v1x0x1/pu?fidx=getArticlewithToped',info)
}
export function DALINEWEB_ARTICLECENTER_V0X0X1_updateArticle (info) {
    return restful.post('/pu-articleserver-v1x0x1/pu?fidx=updateArticle',info)
}
export function DALINEWEB_ARTICLECENTER_V0X0X1_delArticle (info) {
    return restful.post('/pu-articleserver-v1x0x1/pu?fidx=delArticle',info)
}
export function DALINEWEB_ARTICLECENTER_V0X0X1_exchangeOrderID (info) {
    return restful.post('/pu-articleserver-v1x0x1/pu?fidx=exchangeOrderID',info)
}
export function DALINEWEB_ARTICLECENTER_V0X0X1_addArticle (info) {
    return restful.post('/pu-articleserver-v1x0x1/pu?fidx=addArticle',info)
}
    //  position
export function DALINEWEB_ARTICLECENTER_V0X0X1_addPosition (info) {
    return restful.post('/pu-articleserver-v1x0x1/pu?fidx=addPosition',info)
}
export function DALINEWEB_ARTICLECENTER_V0X0X1_getPosition (info) {
    return restful.post('/pu-articleserver-v1x0x1/pu?fidx=getPosition',info)
}
export function DALINEWEB_ARTICLECENTER_V0X0X1_updatePosition (info) {
    return restful.post('/pu-articleserver-v1x0x1/pu?fidx=updatePosition',info)
}

    // other
export function DALINEWEB_ARTICLECENTER_V0X0X1_sendSMSMessage (info) {
        return restful.post('/pu-articleserver-v1x0x1/pu?fidx=sendSMSMessage',info)
    }
// --------------- img -----------------
export function DALINEWEB_IMGCENTER_V0X0X1_addImg (info) {
    return restful.post('/pu-imagecenter-v1x0x1/pu?fidx=addImg',info)
}
export function DALINEWEB_IMGCENTER_V0X0X1_getImgList (info) {
    return restful.post('/pu-imagecenter-v1x0x1/pu?fidx=getImgList',info)
}
export function DALINEWEB_IMGCENTER_V0X0X1_updateImg (info) {
    return restful.post('/pu-imagecenter-v1x0x1/pu?fidx=updateImg',info)
}
export function DALINEWEB_IMGCENTER_V0X0X1_delImg (info) {
    return restful.post('/pu-imagecenter-v1x0x1/pu?fidx=delImg',info)
}
// --------------- wxpublic -----------------
export function DALINEWEB_WXPUBLICCENTER_V0X0X1_addnewmaterial (info) {
    return restful.post('/pu-wxpubliccenter-v1x0x1/pu?fidx=addnewmaterial',info)
}