<template>
<!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
<div id="Resourcedetails" style="width:100vw">
  <div id="a9895889">
    <div id="af14025e">
      <div id="e929ea49">
        账户余额
¥ 7000.00
      </div>
    </div>
    <div id="f294255d">
      <el-select id="a11419ce" placeholder="开发中产品" v-model="val_a11419ce">
        <el-option v-for="item in op_a11419ce" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </div>
    <el-select id="a32464d8" placeholder="运营中产品" v-model="val_a32464d8">
      <el-option v-for="item in op_a32464d8" :key="item.value" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
  </div>
  <div id="a6d70bc4">
    <div id="a035e5cf">
      学会投
    </div>
    <div id="a38aba61">
      (上线日：xxxx年x月x日——到期日：xxxx年x月x日)
    </div>
  </div>
  <div id="afdf9e17">
    <div id="c8b8df85">
      <img src="../assets/img-a50128a2.png" id="a50128a2"/>
      <div id="c264a7d8">
        设计费
      </div>
      <div id="a37339c8">
        (一次性)
      </div>
    </div>
    <img src="../assets/img-a441d03b.png" id="a441d03b"/>
    <div id="a6e6b6ba">
      <img src="../assets/img-a819b096.png" id="a819b096"/>
      <div id="e22b6814">
        小程序
      </div>
      <div id="e15ca769">
        (xx页)
      </div>
      <div id="a494c238">
        ¥ XX.00元
      </div>
    </div>
    <img src="../assets/img-f4af01a2.png" id="f4af01a2"/>
    <div id="a9bbdcf8">
      <img src="../assets/img-ee91bb96.png" id="ee91bb96"/>
      <div id="a90cbb19">
        APP
      </div>
      <div id="b0e6f11d">
        (xx页)
      </div>
      <div id="ac07dde2">
        ¥ XX.00元
      </div>
    </div>
    <img src="../assets/img-a932780c.png" id="a932780c"/>
    <div id="b1c661a3">
      <img src="../assets/img-a9f7ebfe.png" id="a9f7ebfe"/>
      <div id="a9aebb6f">
        Web应用
      </div>
      <div id="a52951f4">
        (xx页)
      </div>
      <div id="a9b6be6f">
        ¥ XX.00元
      </div>
    </div>
    <img src="../assets/img-a8e371e2.png" id="a8e371e2"/>
    <div id="bd3fba6c">
      <img src="../assets/img-a6f4876a.png" id="a6f4876a"/>
      <div id="d11b9960">
        PC端应用
      </div>
      <div id="a2404edf">
        (xx页)
      </div>
      <div id="a9690061">
        ¥ XX.00元
      </div>
    </div>
    <img src="../assets/img-a7df239b.png" id="a7df239b"/>
    <div id="a6f81aa6">
      <img src="../assets/img-a375506d.png" id="a375506d"/>
      <div id="a175430a">
        软著申请
      </div>
      <div id="a9e42ac4">
        ¥ XX.00元
      </div>
    </div>
    <img src="../assets/img-abbaf931.png" id="abbaf931"/>
    <div id="ae39598b">
      <img src="../assets/img-fa19b3a8.png" id="fa19b3a8"/>
      <div id="e132fede">
        技术平台使用费
      </div>
      <div id="ae5e73ef">
        (每年)
      </div>
    </div>
    <img src="../assets/img-dfdda16c.png" id="dfdda16c"/>
    <div id="d4f1962c">
      <img src="../assets/img-cb7e4621.png" id="cb7e4621"/>
      <div id="a5cb4405">
        微信小程序
      </div>
      <div id="aed9cfb6">
        ¥ XX.00元/年
      </div>
    </div>
    <img src="../assets/img-a3e1c565.png" id="a3e1c565"/>
    <div id="a535d22d">
      <img src="../assets/img-afc4eb2b.png" id="afc4eb2b"/>
      <div id="adfe80ca">
        支付宝小程序
      </div>
      <div id="fdd5fb5d">
        ¥ XX.00元/年
      </div>
    </div>
    <img src="../assets/img-a7622793.png" id="a7622793"/>
    <div id="acf14bde">
      <img src="../assets/img-a548979b.png" id="a548979b"/>
      <div id="d79cbf87">
        IOS  APP
      </div>
      <div id="c8cf2d2c">
        ¥ XX.00元/年
      </div>
    </div>
    <img src="../assets/img-b26479f5.png" id="b26479f5"/>
    <div id="ab27d72f">
      <img src="../assets/img-a8e8ef1c.png" id="a8e8ef1c"/>
      <div id="a17db1cb">
        Android  APP
      </div>
      <div id="a94a85ff">
        ¥ XX.00元/年
      </div>
    </div>
    <img src="../assets/img-af8aa814.png" id="af8aa814"/>
    <div id="a2cb3626">
      <img src="../assets/img-a68c7475.png" id="a68c7475"/>
      <div id="a2e49271">
        Mac 客户端
      </div>
      <div id="e2e64750">
        ¥ XX.00元/年
      </div>
    </div>
    <img src="../assets/img-a1877e40.png" id="a1877e40"/>
    <div id="a8da8238">
      <img src="../assets/img-ff782f4f.png" id="ff782f4f"/>
      <div id="a49d409c">
        Windows 客户端
      </div>
      <div id="a93fa566">
        ¥ XX.00元/年
      </div>
    </div>
  </div>
  <div id="a9d5a968">
    <dal-compfloat id="cmpa9d5a968">
    </dal-compfloat>
  </div>
</div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
import dal_compfloat from '../components/dal-compfloat'
export default{
  components:{
    'dal-compfloat':dal_compfloat
  },
  data(){
    return{
    op_a11419ce:[],
    val_a11419ce:'',
    op_a32464d8:[],
    val_a32464d8:''
      //在此注释下方添加自定义变量
    }
  },
  mounted(){
  },
  computed:{
  },
  watch:{
  },
  methods:{
    //在此注释下方添加自定义函数或事件
  }
}
</script>
<style>
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
#a9895889{
  width:1155px;
  position:relative;
  padding-left:248px;
  padding-top:100px;
}
#af14025e{
  width:534px;
  height:388px;
  position:relative;
  display:inline-block;
  vertical-align: middle;
}
#af14025e{
  background-image:url('../assets/img-a49575d9.png');
  background-size: cover;
}
#e929ea49{
  padding-top:114px;
  padding-bottom:96px;
}
#e929ea49{
  text-align:center;
  color:#ff7329;
  font-size:60px;
  font-family:PingFang SC;
}
#f294255d{
  width:236px;
  position:relative;
  margin-left:133px;
  padding-bottom:270px;
  display:inline-block;
  vertical-align: middle;
}
#f294255d .el-select{
  width:236px;
}
#a9895889 .el-select{
  width:200px;
  margin-left:52px;
  padding-bottom:288px;
}
#a6d70bc4{
  width:683px;
  position:relative;
  margin-top:100px;
  padding-left:483px;
}
#a035e5cf{
}
#a035e5cf{
  text-align:center;
  color:#141620;
  font-size:45px;
  font-family:PingFang SC;
}
#a38aba61{
  margin-top:20px;
}
#a38aba61{
  text-align:left;
  color:#6f7484;
  font-size:30px;
  font-family:PingFang SC;
}
#afdf9e17{
  width:1323px;
  margin-top:60px;
  margin-left:163px;
  margin-bottom:410px;
}
#afdf9e17{
  background-color:rgb(252,252,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:4px 4px 4px rgba(0,0,0,0.16);
}
#c8b8df85{
  width:265px;
  position:relative;
  padding-left:66px;
  padding-top:50px;
}
#a50128a2{
  width:10px;
  height:10px;
  padding-top:20px;
  padding-bottom:19px;
  display:inline-block;
  vertical-align: middle;
}
#a50128a2{
  vertical-align:middle;
}
#c264a7d8{
  display:inline-block;
  vertical-align: middle;
}
#c264a7d8{
  text-align:left;
  color:#141620;
  font-size:35px;
  font-family:PingFang SC;
}
#a37339c8{
  padding-top:7px;
  display:inline-block;
  vertical-align: middle;
}
#a37339c8{
  text-align:left;
  color:#6f7484;
  font-size:30px;
  font-family:PingFang SC;
}
#a441d03b{
  width:1216px;
  height:3px;
  margin-top:30px;
  padding-left:54px;
}
#a441d03b{
  vertical-align:middle;
}
#a6e6b6ba{
  width:1183px;
  position:relative;
  margin-top:30px;
  padding-left:66px;
}
#a819b096{
  width:10px;
  height:10px;
  padding-top:20px;
  padding-bottom:19px;
  display:inline-block;
  vertical-align: middle;
}
#a819b096{
  vertical-align:middle;
}
#e22b6814{
  display:inline-block;
  vertical-align: middle;
}
#e22b6814{
  text-align:left;
  color:#141620;
  font-size:35px;
  font-family:PingFang SC;
}
#e15ca769{
  padding-top:7px;
  display:inline-block;
  vertical-align: middle;
}
#e15ca769{
  text-align:left;
  color:#6f7484;
  font-size:30px;
  font-family:PingFang SC;
}
#a494c238{
  padding-top:4px;
  padding-bottom:3px;
  display:inline-block;
  vertical-align: middle;
}
#a494c238{
  text-align:left;
  color:#6f7484;
  font-size:30px;
  font-family:PingFang SC;
}
#f4af01a2{
  width:1216px;
  height:3px;
  margin-top:30px;
  padding-left:54px;
}
#f4af01a2{
  vertical-align:middle;
}
#a9bbdcf8{
  width:1183px;
  position:relative;
  margin-top:30px;
  padding-left:66px;
}
#ee91bb96{
  width:10px;
  height:10px;
  padding-top:20px;
  padding-bottom:19px;
  display:inline-block;
  vertical-align: middle;
}
#ee91bb96{
  vertical-align:middle;
}
#a90cbb19{
  display:inline-block;
  vertical-align: middle;
}
#a90cbb19{
  text-align:left;
  color:#141620;
  font-size:35px;
  font-family:PingFang SC;
}
#b0e6f11d{
  padding-top:7px;
  display:inline-block;
  vertical-align: middle;
}
#b0e6f11d{
  text-align:left;
  color:#6f7484;
  font-size:30px;
  font-family:PingFang SC;
}
#ac07dde2{
  padding-top:4px;
  padding-bottom:3px;
  display:inline-block;
  vertical-align: middle;
}
#ac07dde2{
  text-align:left;
  color:#6f7484;
  font-size:30px;
  font-family:PingFang SC;
}
#a932780c{
  width:1216px;
  height:3px;
  margin-top:30px;
  padding-left:54px;
}
#a932780c{
  vertical-align:middle;
}
#b1c661a3{
  width:1183px;
  position:relative;
  margin-top:30px;
  padding-left:66px;
}
#a9f7ebfe{
  width:10px;
  height:10px;
  padding-top:20px;
  padding-bottom:19px;
  display:inline-block;
  vertical-align: middle;
}
#a9f7ebfe{
  vertical-align:middle;
}
#a9aebb6f{
  display:inline-block;
  vertical-align: middle;
}
#a9aebb6f{
  text-align:left;
  color:#141620;
  font-size:35px;
  font-family:PingFang SC;
}
#a52951f4{
  padding-top:7px;
  display:inline-block;
  vertical-align: middle;
}
#a52951f4{
  text-align:left;
  color:#6f7484;
  font-size:30px;
  font-family:PingFang SC;
}
#a9b6be6f{
  padding-top:4px;
  padding-bottom:3px;
  display:inline-block;
  vertical-align: middle;
}
#a9b6be6f{
  text-align:left;
  color:#6f7484;
  font-size:30px;
  font-family:PingFang SC;
}
#a8e371e2{
  width:1216px;
  height:3px;
  margin-top:30px;
  padding-left:54px;
}
#a8e371e2{
  vertical-align:middle;
}
#bd3fba6c{
  width:1183px;
  position:relative;
  margin-top:30px;
  padding-left:66px;
}
#a6f4876a{
  width:10px;
  height:10px;
  padding-top:20px;
  padding-bottom:19px;
  display:inline-block;
  vertical-align: middle;
}
#a6f4876a{
  vertical-align:middle;
}
#d11b9960{
  display:inline-block;
  vertical-align: middle;
}
#d11b9960{
  text-align:left;
  color:#141620;
  font-size:35px;
  font-family:PingFang SC;
}
#a2404edf{
  padding-top:7px;
  display:inline-block;
  vertical-align: middle;
}
#a2404edf{
  text-align:left;
  color:#6f7484;
  font-size:30px;
  font-family:PingFang SC;
}
#a9690061{
  padding-top:4px;
  padding-bottom:3px;
  display:inline-block;
  vertical-align: middle;
}
#a9690061{
  text-align:left;
  color:#6f7484;
  font-size:30px;
  font-family:PingFang SC;
}
#a7df239b{
  width:1216px;
  height:3px;
  margin-top:30px;
  padding-left:54px;
}
#a7df239b{
  vertical-align:middle;
}
#a6f81aa6{
  width:1183px;
  position:relative;
  margin-top:30px;
  padding-left:66px;
}
#a375506d{
  width:10px;
  height:10px;
  padding-top:20px;
  padding-bottom:19px;
  display:inline-block;
  vertical-align: middle;
}
#a375506d{
  vertical-align:middle;
}
#a175430a{
  display:inline-block;
  vertical-align: middle;
}
#a175430a{
  text-align:left;
  color:#141620;
  font-size:35px;
  font-family:PingFang SC;
}
#a9e42ac4{
  padding-top:4px;
  padding-bottom:3px;
  display:inline-block;
  vertical-align: middle;
}
#a9e42ac4{
  text-align:left;
  color:#6f7484;
  font-size:30px;
  font-family:PingFang SC;
}
#abbaf931{
  width:1216px;
  height:3px;
  margin-top:30px;
  padding-left:54px;
}
#abbaf931{
  vertical-align:middle;
}
#ae39598b{
  width:375px;
  position:relative;
  margin-top:30px;
  padding-left:66px;
}
#fa19b3a8{
  width:10px;
  height:10px;
  padding-top:20px;
  padding-bottom:19px;
  display:inline-block;
  vertical-align: middle;
}
#fa19b3a8{
  vertical-align:middle;
}
#e132fede{
  display:inline-block;
  vertical-align: middle;
}
#e132fede{
  text-align:left;
  color:#141620;
  font-size:35px;
  font-family:PingFang SC;
}
#ae5e73ef{
  padding-top:7px;
  display:inline-block;
  vertical-align: middle;
}
#ae5e73ef{
  text-align:left;
  color:#6f7484;
  font-size:30px;
  font-family:PingFang SC;
}
#dfdda16c{
  width:1216px;
  height:3px;
  margin-top:30px;
  padding-left:54px;
}
#dfdda16c{
  vertical-align:middle;
}
#d4f1962c{
  width:1183px;
  position:relative;
  margin-top:30px;
  padding-left:66px;
}
#cb7e4621{
  width:10px;
  height:10px;
  padding-top:20px;
  padding-bottom:19px;
  display:inline-block;
  vertical-align: middle;
}
#cb7e4621{
  vertical-align:middle;
}
#a5cb4405{
  display:inline-block;
  vertical-align: middle;
}
#a5cb4405{
  text-align:left;
  color:#141620;
  font-size:35px;
  font-family:PingFang SC;
}
#aed9cfb6{
  padding-top:4px;
  padding-bottom:3px;
  display:inline-block;
  vertical-align: middle;
}
#aed9cfb6{
  text-align:right;
  color:#6f7484;
  font-size:30px;
  font-family:PingFang SC;
}
#a3e1c565{
  width:1216px;
  height:3px;
  margin-top:30px;
  padding-left:54px;
}
#a3e1c565{
  vertical-align:middle;
}
#a535d22d{
  width:1183px;
  position:relative;
  margin-top:30px;
  padding-left:66px;
}
#afc4eb2b{
  width:10px;
  height:10px;
  padding-top:20px;
  padding-bottom:19px;
  display:inline-block;
  vertical-align: middle;
}
#afc4eb2b{
  vertical-align:middle;
}
#adfe80ca{
  display:inline-block;
  vertical-align: middle;
}
#adfe80ca{
  text-align:left;
  color:#141620;
  font-size:35px;
  font-family:PingFang SC;
}
#fdd5fb5d{
  padding-top:4px;
  padding-bottom:3px;
  display:inline-block;
  vertical-align: middle;
}
#fdd5fb5d{
  text-align:right;
  color:#6f7484;
  font-size:30px;
  font-family:PingFang SC;
}
#a7622793{
  width:1216px;
  height:3px;
  margin-top:30px;
  padding-left:54px;
}
#a7622793{
  vertical-align:middle;
}
#acf14bde{
  width:1183px;
  position:relative;
  margin-top:30px;
  padding-left:66px;
}
#a548979b{
  width:10px;
  height:10px;
  padding-top:20px;
  padding-bottom:19px;
  display:inline-block;
  vertical-align: middle;
}
#a548979b{
  vertical-align:middle;
}
#d79cbf87{
  display:inline-block;
  vertical-align: middle;
}
#d79cbf87{
  text-align:left;
  color:#141620;
  font-size:35px;
  font-family:PingFang SC;
}
#c8cf2d2c{
  padding-top:4px;
  padding-bottom:3px;
  display:inline-block;
  vertical-align: middle;
}
#c8cf2d2c{
  text-align:right;
  color:#6f7484;
  font-size:30px;
  font-family:PingFang SC;
}
#b26479f5{
  width:1216px;
  height:3px;
  margin-top:30px;
  padding-left:54px;
}
#b26479f5{
  vertical-align:middle;
}
#ab27d72f{
  width:1183px;
  position:relative;
  margin-top:30px;
  padding-left:66px;
}
#a8e8ef1c{
  width:10px;
  height:10px;
  padding-top:20px;
  padding-bottom:19px;
  display:inline-block;
  vertical-align: middle;
}
#a8e8ef1c{
  vertical-align:middle;
}
#a17db1cb{
  display:inline-block;
  vertical-align: middle;
}
#a17db1cb{
  text-align:left;
  color:#141620;
  font-size:35px;
  font-family:PingFang SC;
}
#a94a85ff{
  padding-top:4px;
  padding-bottom:3px;
  display:inline-block;
  vertical-align: middle;
}
#a94a85ff{
  text-align:right;
  color:#6f7484;
  font-size:30px;
  font-family:PingFang SC;
}
#af8aa814{
  width:1216px;
  height:3px;
  margin-top:30px;
  padding-left:54px;
}
#af8aa814{
  vertical-align:middle;
}
#a2cb3626{
  width:1183px;
  position:relative;
  margin-top:30px;
  padding-left:66px;
}
#a68c7475{
  width:10px;
  height:10px;
  padding-top:20px;
  padding-bottom:19px;
  display:inline-block;
  vertical-align: middle;
}
#a68c7475{
  vertical-align:middle;
}
#a2e49271{
  display:inline-block;
  vertical-align: middle;
}
#a2e49271{
  text-align:left;
  color:#141620;
  font-size:35px;
  font-family:PingFang SC;
}
#e2e64750{
  padding-top:4px;
  padding-bottom:3px;
  display:inline-block;
  vertical-align: middle;
}
#e2e64750{
  text-align:right;
  color:#6f7484;
  font-size:30px;
  font-family:PingFang SC;
}
#a1877e40{
  width:1216px;
  height:3px;
  margin-top:30px;
  padding-left:54px;
}
#a1877e40{
  vertical-align:middle;
}
#a8da8238{
  width:1183px;
  position:relative;
  margin-top:30px;
  padding-left:66px;
  padding-bottom:50px;
}
#ff782f4f{
  width:10px;
  height:10px;
  padding-top:20px;
  padding-bottom:19px;
  display:inline-block;
  vertical-align: middle;
}
#ff782f4f{
  vertical-align:middle;
}
#a49d409c{
  display:inline-block;
  vertical-align: middle;
}
#a49d409c{
  text-align:left;
  color:#141620;
  font-size:35px;
  font-family:PingFang SC;
}
#a93fa566{
  padding-top:4px;
  padding-bottom:3px;
  display:inline-block;
  vertical-align: middle;
}
#a93fa566{
  text-align:right;
  color:#6f7484;
  font-size:30px;
  font-family:PingFang SC;
}
#a9d5a968{
  width:150px;
  position:fixed;
  z-index: 50;
  top:1569px;
  left:1740px;
}
#a9d5a968{
  background-color:rgb(20,22,32,1);
  border-radius:5px 5px 5px 5px;
}
/*在此注释下方添加自定义样式*/
</style>
